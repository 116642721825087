import React from 'react';

import PendingTable from './PendingTable';

const pendingRoute = [
    {
        path: '/pending_user',
        exact: true,
        component: PendingTable
    }
];

export default pendingRoute;
