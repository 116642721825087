import { Button, CircularProgress, Dialog, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

import { addLearner, updateLearner } from './TableService';

const LearnerEditorDialog = ({ learner, open, handleClose }) => {
    const [state, setState] = useState({
        lt_code: '',
        lt_name: ''
    });
    const [submit, setSubmit] = useState(false);

    const handleFormSubmit = () => {
        let { lt_code } = state;
        setSubmit(true);
        if (lt_code) {
            updateLearner(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data update successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to update',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        } else {
            addLearner(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data saved successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to save',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        }
    };

    useEffect(() => {
        if (learner) {
            setState({
                ...learner,
                profile_prev: learner?.profile_pict,
                profile_pict: ''
            });
        }
    }, [learner]);

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
            <div className="p-6">
                <h4 className="mb-5">
                    {state.lt_code ? 'Update Learner Type' : 'Add Learner Type'}
                </h4>
                <ValidatorForm onSubmit={handleFormSubmit}>
                    <Grid className="mb-4" container spacing={4}>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className="w-full mb-1"
                                label="Title"
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        lt_name: value
                                    }))
                                }
                                type="text"
                                name="lt_name"
                                value={state?.lt_name || ''}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    <div className="flex justify-end items-center">
                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="text-white ml-2"
                            disabled={submit}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-white"
                                />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

export default LearnerEditorDialog;
