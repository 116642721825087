import { API, setAuthToken } from 'config/API';

export const getAllCity = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/city');
};
export const deleteCity = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete('admin/city/delete/' + params);
};
export const addNewCity = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/city/add', params);
};
export const updateCity = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/city/edit', params);
};
export const getCityByCode = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('apps/city?country_code=' + params);
};
