import { Button, CircularProgress, Dialog, Grid, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

import { addNewConferences, updateConferences } from './TableService';

const ConferenceEditorDialog = ({ conferences, confTypes, open, handleClose }) => {
    const [state, setState] = useState({
        conf_code: '',
        conf_type: '',
        conf_link: '',
        created_by: ''
    });
    const [submit, setSubmit] = useState(false);

    const handleFormSubmit = () => {
        let { conf_code } = state;
        setSubmit(true);
        if (conf_code) {
            updateConferences(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data update successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to update',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        } else {
            addNewConferences(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data saved successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to save',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        }
    };

    useEffect(() => {
        if (conferences) {
            setState({
                ...conferences
            });
        }
    }, [conferences]);

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
            <div className="p-6">
                <h4 className="mb-5">
                    {state.conf_code ? 'Update Conference' : 'Add Conference'}
                </h4>
                <ValidatorForm onSubmit={handleFormSubmit}>
                    <Grid className="mb-4" container spacing={4}>
                    <Grid item sm={12} xs={12}>
                            <Select
                                value={state?.conf_type}
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        conf_type: value
                                    }))
                                }
                                displayEmpty
                                variant="outlined"
                                className="w-full mb-1"
                            >
                                <MenuItem value="">
                                    <em>Select Conference Type</em>
                                </MenuItem>
                                {confTypes.map((item) => (
                                    <MenuItem
                                        value={item.value}
                                        key={item.value}
                                        selected={item.value === state?.conf_type}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className="w-full mb-1"
                                label="Link"
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        conf_link: value
                                    }))
                                }
                                type="text"
                                name="link"
                                value={state?.conf_link || ''}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    <div className="flex justify-end items-center">
                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="text-white ml-2"
                            disabled={submit}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-white"
                                />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

export default ConferenceEditorDialog;
