import React from 'react';

import ExamTable from './ExamTable';

const examRoute = [
    {
        path: '/exam_type',
        exact: true,
        component: ExamTable
    }
];

export default examRoute;
