import { Card, Icon, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    gridCard: {
        '& .grid__card-overlay': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            opacity: 0,
            transition: 'all 250ms ease-in-out',
            background: 'rgba(0, 0, 0, 0.67)',
            borderRadius: 5,

            '& > div:nth-child(2)': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                zIndex: -1
            }
        },
        '& .grid__card-bottom': {
            '& .email': {
                display: 'none'
            }
        },
        '&:hover': {
            '& .grid__card-overlay': {
                opacity: 1
            }
        }
    }
}));

const GridView = ({
    name,
    image,
    handleDelete,
    idx,
    data,
    handleEdit,
    desc,
    showHtml
}) => {
    const classes = useStyles();

    return (
        <Card
            className={clsx('flex-column h-full p-3', classes.gridCard)}
            elevation={6}
        >
            <div className="grid__card-top text-center relative">
                <img
                    className="block w-full radius-5 object-fit"
                    src={image}
                    alt="project"
                    height="150px"
                />
                <div className="grid__card-overlay flex-column h-full">
                    <div className="flex items-center justify-center h-full">
                        <IconButton
                            className="mr-3"
                            onClick={() => handleEdit(data)}
                        >
                            <Icon
                                fontSize="small"
                                className="cursor-pointer text-white"
                            >
                                edit
                            </Icon>
                        </IconButton>
                        <IconButton onClick={() => handleDelete(idx)}>
                            <Icon
                                fontSize="small"
                                className="cursor-pointer text-white"
                            >
                                delete
                            </Icon>
                        </IconButton>
                    </div>
                </div>
            </div>
            <div className="grid__card-bottom text-center py-2">
                {showHtml ? (
                    <p
                        className="m-0 show-html-organized"
                        dangerouslySetInnerHTML={{
                            __html: name
                        }}
                    ></p>
                ) : (
                    <p className="m-0 text-elipse">{name}</p>
                )}
                {desc && <small className="date text-muted">{desc}</small>}
            </div>
        </Card>
    );
};

export default GridView;
