import React from 'react';

import QueueTable from './QueueTable';

const queueRoute = [
    {
        path: '/people_in_queue',
        exact: true,
        component: QueueTable
    }
];

export default queueRoute;
