import { Button, CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

import { getSetting, updateSetting } from './TableService';

const SettingTable = () => {
    const [textFooter, setTextFooter] = useState('');
    const [submit, setSubmit] = useState(false);

    const _submit = () => {
        setSubmit(true);
        updateSetting({ about_text: textFooter })
            .then(() => {
                Swal.fire({
                    title: 'Success',
                    text: 'data update successfully',
                    timer: 2000,
                    showConfirmButton: false,
                    icon: 'success'
                }).then(() => {
                    setSubmit(false);
                });
            })
            .catch((err) => {
                console.log('err', err);
                const error = err.response.data;
                Swal.fire({
                    title: 'Failed',
                    text:
                        error?.code === 1
                            ? error?.data[0]
                            : 'data failed to update',
                    timer: 2000,
                    showConfirmButton: false,
                    icon: 'error'
                }).then(() => {
                    setSubmit(false);
                });
            });
    };

    useEffect(() => {
        getSetting().then(({ data }) => setTextFooter(data?.data?.about_text));
    }, []);
    return (
        <div className="m-sm-30">
            <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <h5 className="mb-4">Text Footer</h5>
                    <ValidatorForm onSubmit={_submit}>
                        <TextValidator
                            className="w-full mb-1"
                            placeholder="Text Footer"
                            type="text"
                            name="text_footer"
                            onChange={({ target: { value } }) =>
                                setTextFooter(value)
                            }
                            value={textFooter || ''}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            variant="outlined"
                            multiline
                            rows={4}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="text-white mt-2"
                            disabled={submit}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-white"
                                />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </ValidatorForm>
                </Grid>
            </Grid>
        </div>
    );
};

export default SettingTable;
