import { API, setAuthToken } from 'config/API';

export const getMovie = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/movie');
};
export const deleteMovie = async (code) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete(`admin/movie/delete/${code}`);
};
export const addMovie = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/movie/add', params);
};
export const updateMovie = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/movie/edit', params);
};
