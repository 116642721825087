import { Button, CircularProgress, Dialog, FormControlLabel, Grid, Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

import { addNewCountry, updateCountry } from './TableService';

const CountryEditorDialog = ({ country, open, handleClose }) => {
    const [state, setState] = useState({
        status: '',
        country_name: '',
        country_code: ''
    });
    const [submit, setSubmit] = useState(false);

    const handleFormSubmit = () => {
        let { country_code } = state;
        setSubmit(true);
        if (country_code) {
            updateCountry(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data update successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to update',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        } else {
            addNewCountry(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data saved successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to save',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        }
    };

    useEffect(() => {
        if (country) {
            setState({
                ...country
            });
        }
    }, [country]);

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
            <div className="p-6">
                <h4 className="mb-5">
                    {state.country_code ? 'Update Country' : 'Add Country'}
                </h4>
                <ValidatorForm onSubmit={handleFormSubmit}>
                    <Grid className="mb-4" container spacing={4}>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className="w-full mb-1"
                                label="Name"
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        country_name: value
                                    }))
                                }
                                type="text"
                                name="name"
                                value={state?.country_name || ''}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                variant="outlined"
                            />
                        </Grid>
                        {state?.country_code !== '' ? (
                            <Grid item sm={12} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={state.status === 1}
                                            onChange={({ target }) =>
                                                setState((pref) => ({
                                                    ...pref,
                                                    status: target.checked
                                                        ? 1
                                                        : 0
                                                }))
                                            }
                                            name="active"
                                            color="primary"
                                        />
                                    }
                                    label="Active ?"
                                />
                            </Grid>
                        ) : null}
                    </Grid>

                    <div className="flex justify-end items-center">
                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="text-white ml-2"
                            disabled={submit}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-white"
                                />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

export default CountryEditorDialog;
