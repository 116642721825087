export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'dashboard'
    },
    {
        name: 'Users Management',
        path: '/users',
        icon: 'person'
    },
    {
        name: 'Admin Management',
        path: '/admins',
        icon: 'admin_panel_settings'
    },
    {
        name: 'Group',
        path: '/group',
        icon: 'groups',
    },
    {
        name: 'Conference',
        path: '/conference',
        icon: 'videocam'
    },
    {
        name: 'Pending User',
        path: '/pending_user',
        icon: 'hourglass_top'
    },
    {
        name: 'Matched People',
        path: '/match_people',
        icon: 'shuffle'
    },
    {
        name: 'People In Queue',
        path: '/people_in_queue',
        icon: 'people'
    },
    {
        name: 'Country',
        path: '/country',
        icon: 'flag'
    },
    {
        name: 'City',
        path: '/city',
        icon: 'travel_explore'
    },
    {
        name: 'University',
        path: '/university',
        icon: 'school'
    },
    {
        name: 'Hobby',
        path: '/hobby',
        icon: 'sports_esports'
    },
    {
        name: 'Movie Genre',
        path: '/movie_genre',
        icon: 'movie'
    },
    {
        name: 'Learner Type',
        path: '/learner_type',
        icon: 'local_library'
    },
    {
        name: 'Exam Type',
        path: '/exam_type',
        icon: 'format_list_bulleted'
    },
    {
        name: 'Zoom Host',
        path: '/zoom_host',
        icon: 'video_camera_front'
    },
    {
        name: 'Break The Ice',
        path: '/breake_the_ice',
        icon: 'question_answer'
    },
    {
        name: 'Setting',
        path: '/setting',
        icon: 'settings'
    }
];
