import { API, setAuthToken } from 'config/API';

export const getAllUniversity = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/university');
};
export const deleteUniversity = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete('admin/university/delete/' + params);
};
export const addNewUniversity = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/university/add', params);
};
export const updateUniversity = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/university/edit', params);
};
