import { API, setAuthToken } from 'config/API';

export const getLearner = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/learner');
};
export const deleteLearner = async (code) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete(`admin/learner/delete/${code}`);
};
export const addLearner = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/learner/add', params);
};
export const updateLearner = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/learner/edit', params);
};
