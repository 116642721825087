import React from 'react';

import CountryTable from './CountryTable';

const countryRoute = [
    {
        path: '/country',
        exact: true,
        component: CountryTable
    }
];

export default countryRoute;
