import { API, setAuthToken } from 'config/API';

export const getAllMatchPeople = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/match_people');
};
export const deleteMatchPeople = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete('admin/match_people/delete/' + params);
};
export const addNewMatchPeople = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/match_people/add', params);
};
export const updateMatchPeople = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/match_people/edit', params);
};
