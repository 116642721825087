import { API, setAuthToken } from 'config/API';

export const getAllGroup = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/group');
};
export const deleteGroup = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete('admin/group/delete/' + params);
};
export const addNewGroup = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/group/add', params);
};
export const updateGroup = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/group/edit', params);
};
