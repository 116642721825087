import { API, setAuthToken } from 'config/API';

export const getDashboard = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/dashboard');
};

export const getDashboardChart = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/dashboard/statistic', params);
};

export const getDashboardVisitorStat = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/dashboard/visitorstat', params);
}

export const getDashboardVisitor = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/dashboard/visitor', params);
}
