import React from 'react';

import CityTable from './CityTable';

const cityRoute = [
    {
        path: '/city',
        exact: true,
        component: CityTable
    }
];

export default cityRoute;
