import { Card, Grid, Icon, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import clsx from 'clsx';
import { setMonth } from 'date-fns';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'fit-content',
        height: 'fit-content',
        gap: '0.5rem'
    }
}));

const Chart = ({ label, value, changeMonth, changeYear, month, year }) => {
    const classes = useStyles();

    const [optionMonth, setOptionMonth] = useState([
        {
            label: 'January',
            value: '01',
            show: true
        },
        {
            label: 'February',
            value: '02',
            show: true
        },
        {
            label: 'March',
            value: '03',
            show: true
        },
        {
            label: 'April',
            value: '04',
            show: true
        },
        {
            label: 'May',
            value: '05',
            show: true
        },
        {
            label: 'June',
            value: '06',
            show: true
        },
        {
            label: 'July',
            value: '07',
            show: true
        },
        {
            label: 'August',
            value: '08',
            show: true
        },
        {
            label: 'September',
            value: '09',
            show: true
        },
        {
            label: 'October',
            value: '10',
            show: true
        },
        {
            label: 'November',
            value: '11',
            show: true
        },
        {
            label: 'December',
            value: '12',
            show: true
        }
    ]);

    useEffect(() => {
        if (+year === new Date().getFullYear()) {
            const date = new Date();
            let currMonth = date.getMonth() + 1;
            currMonth = currMonth < 10 ? '0' + currMonth : currMonth;

            setOptionMonth(optionMonth.map((item) => {
                item.show = item.value <= currMonth;
                return item;
            }));

            if (+month > +currMonth) {
                changeMonth(currMonth);
            }

        } else {
            setOptionMonth(optionMonth.map((item) => {
                item.show = true;
                return item;
            }));
        }
    }, [year]);


    const optionYear = Array.from({ length: new Date().getFullYear() - 2020 }, (_, index) => {
        const year = 2021 + index;
        return { label: year.toString(), value: year.toString() };
    }).sort((a, b) => b.value - a.value);
    const data = {
        labels: label,
        datasets: [
            {
                label: 'New User',
                data: value,
                fill: false,
                backgroundColor: '#F09856',
                borderColor: '#FAB079',
                tension: 0.3
            }
        ]
    };

    return (
        <Card elevation={6} className="p-6 mb-5">
            <Grid container justifyContent="space-around" spacing={2}>
                <Grid item sm="6" xs="12" className="mb-2">
                    <h4 className={clsx(classes.title, "m-0")} style={{

                    }}><Icon>person_add_alt_1</Icon> New User Registration</h4>
                </Grid>
                <Grid item sm="4" xs="12">
                    <Select
                        displayEmpty
                        value={month}
                        onChange={({ target: { value } }) => changeMonth(value)}
                        variant="outlined"
                        className="w-full mb-1"
                        size="small"
                    >
                        <MenuItem value="">
                            <em>Select Month</em>
                        </MenuItem>
                        {optionMonth
                            ?.filter(month => month.show)
                            .sort((a, b) => +b.value - +a.value )
                            .map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                    {item.label}
                                </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item sm="2" xs="12">
                    <Select
                        displayEmpty
                        value={year}
                        onChange={({ target: { value } }) => changeYear(value)}
                        variant="outlined"
                        className="w-full mb-1"
                        size="small"
                    >
                        <MenuItem value="">
                            <em>Select Year</em>
                        </MenuItem>
                        {optionYear?.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
            <div className="container-chart">
                <Line
                    data={data}
                    options={{
                        plugins: {
                        legend: {
                            display: false
                        }
                    },
                    scales: {
                        y: {
                            ticks: {
                                callback: (val, id, vals) => {
                                    return val % 1 === 0 ? val : null;
                                }
                            },
                            beginAtZero: true
                        }
                    },
                    maintainAspectRatio: false
                    }}
                />
            </div>
        </Card>
    );
};

export default Chart;
