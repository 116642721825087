import { API, setAuthToken } from 'config/API';

export const getBTI = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/bti');
};
export const deleteBTI = async (code) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete(`admin/bti/delete/${code}`);
};
export const addBTI = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/bti/add', params);
};
export const updateBTI = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/bti/edit', params);
};
