import React from 'react';

import LearnerTable from './LearnerTable';

const learnerRoute = [
    {
        path: '/learner_type',
        exact: true,
        component: LearnerTable
    }
];

export default learnerRoute;
