import { Button, CircularProgress, Dialog, FormControlLabel, Grid, Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

import { addNewGroup, updateGroup } from './TableService';

const GroupEditorDialog = ({ group, open, handleClose }) => {
    const [state, setState] = useState({
        group_code: '',
        group_name: '',
        group_desc: '',
        is_open: 0
    });
    const [submit, setSubmit] = useState(false);

    const handleFormSubmit = () => {
        let { group_code } = state;
        setSubmit(true);
        if (group_code) {
            updateGroup(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data update successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to update',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        } else {
            addNewGroup(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data saved successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to save',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        }
    };

    useEffect(() => {
        if (group) {
            setState({
                ...group
            });
        }
    }, [group]);

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
            <div className="p-6">
                <h4 className="mb-5">
                    {state.group_code ? 'Update Group' : 'Add Group'}
                </h4>
                <ValidatorForm onSubmit={handleFormSubmit}>
                    <Grid className="mb-4" container spacing={4}>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className="w-full mb-1"
                                label="Name"
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        group_name: value
                                    }))
                                }
                                type="text"
                                name="name"
                                value={state?.group_name || ''}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className="w-full mb-1"
                                label="Desc"
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        group_desc: value
                                    }))
                                }
                                type="text"
                                name="desc"
                                value={state?.group_desc || ''}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                variant="outlined"
                            />
                        </Grid>
                        {state?.group_code !== '' ? (
                            <Grid item sm={12} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={state.is_open === 1}
                                            onChange={({ target }) =>
                                                setState((pref) => ({
                                                    ...pref,
                                                    is_open: target.checked
                                                        ? 1
                                                        : 0
                                                }))
                                            }
                                            name="active"
                                            color="primary"
                                        />
                                    }
                                    label="Active ?"
                                />
                            </Grid>
                        ) : null}
                    </Grid>

                    <div className="flex justify-end items-center">
                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="text-white ml-2"
                            disabled={submit}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-white"
                                />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

export default GroupEditorDialog;
