import { Card, Grid, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    icon: {
        fontSize: '44px',
        opacity: 0.6,
    },
    activeIcon: {
        color: palette.secondary.main
    },
    nonActiveIcon: {
        color: palette.primary.main
    }
}));

const ConferenceStatCards = ({ active, notActive }) => {
    const classes = useStyles();
    const [activeCount, setActiveCount] = useState(0);
    const [notActiveCount, setNotActiveCount] = useState(0);

    useEffect(() => {
        if (active) {
            setActiveCount(active);
        }
        if (notActive) {
            setNotActiveCount(notActive);
        }
    }, [active, notActive]);

    return (
        <Grid container spacing={3} className="mb-3">
            <Grid item xs={12} md={6}>
                <Card
                    className="flex flex-wrap justify-between items-center p-sm-24 bg-primary"
                    elevation={6}
                >
                    <div className="flex items-center">
                        <Icon className={clsx(classes.icon, classes.activeIcon)}>videocam</Icon>
                        <div className="ml-3">
                            <small className="text-muted">Active Conference</small>
                            <h6 className="m-0 mt-1 text-secondary font-medium">
                                <CountUp end={activeCount} />
                            </h6>
                        </div>
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card
                    className="flex flex-wrap justify-between items-center p-sm-24 bg-paper"
                    elevation={6}
                >
                    <div className="flex items-center">
                        <Icon className={clsx(classes.icon, classes.nonActiveIcon)}>videocam</Icon>
                        <div className="ml-3">
                            <small className="text-muted">Non - Active Conference</small>
                            <h6 className="m-0 mt-1 text-primary font-medium">
                                <CountUp end={notActiveCount} />
                            </h6>
                        </div>
                    </div>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ConferenceStatCards;
