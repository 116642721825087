import {
    Button,
    Card,
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2';

import DetailUserDialog from './DetailUserDialog';
import { deleteUser, getAllUser } from './TableService';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    productTable: {
        '& thead': {
            '& th:first-child': {
                paddingLeft: 16
            }
        },
        '& td': {
            borderBottom: 'none'
        },
        '& td:first-child': {
            paddingLeft: '16px !important'
        }
    }
}));

const UsersTable = () => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [userList, setUserList] = useState(null);
    const [detailUser, setDetailUser] = useState(null);
    const [openDetail, setOpenDetail] = useState(false);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleClose = () => {
        setOpenDetail(false);
        setDetailUser(null);
    };

    const updatePageData = (param) => {
        setLoading(true);
        getAllUser({ ...param })
            .then(({ data }) => {
                setUserList(data.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log('err', err);
                setLoading(false);
            });
    };

    useEffect(() => {
        updatePageData();
    }, []);

    const handleDelete = (code) => {
        Swal.fire({
            title: 'Warning',
            text: 'are you sure?',
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'Cancel'
        }).then((res) => {
            if (res.isConfirmed) {
                deleteUser(code)
                    .then((res) => {
                        Swal.fire({
                            title: 'Success',
                            text: 'data success to delete',
                            icon: 'success',
                            timer: 2000
                        });
                        updatePageData();
                    })
                    .catch((err) => {
                        console.log('er', err);
                        Swal.fire({
                            title: 'Failed',
                            text: 'data failed to delete',
                            icon: 'error',
                            timer: 2000
                        });
                    });
            }
        });
    };

    const dataUser = userList
        ?.filter((item) =>
            Object.keys(item).some((key) =>
                search.includes(key)
                    ? false
                    : item[key]
                          .toString()
                          .toLowerCase()
                          .includes(search.toLowerCase())
            )
        )
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <div className="m-sm-30">
            <Card className="w-full" elevation={6}>
                <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    className="mb-2 px-4 mt-3"
                >
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            onChange={({ target: { value } }) =>
                                setSearch(value)
                            }
                            size="small"
                            className="w-full"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon size="small" color="action">
                                            search
                                        </Icon>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <ScrollBar className="flex-grow flex-column relative h-full">
                    <Table
                        className={clsx(
                            'whitespace-pre min-w-750',
                            classes.productTable
                        )}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">No</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell align="center">View</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow hover>
                                    <TableCell
                                        className="px-0"
                                        align="center"
                                        colSpan={6}
                                    >
                                        <CircularProgress size={25} />
                                    </TableCell>
                                </TableRow>
                            ) : dataUser?.length <= 0 ? (
                                <TableRow hover>
                                    <TableCell
                                        className="px-0"
                                        align="center"
                                        colSpan={6}
                                    >
                                        No data
                                    </TableCell>
                                </TableRow>
                            ) : (
                                dataUser?.map((item, index) => (
                                    <TableRow hover key={item.users_code}>
                                        <TableCell
                                            className="px-0"
                                            align="center"
                                        >
                                            {page * rowsPerPage + index + 1}
                                        </TableCell>
                                        <TableCell className="px-0">
                                            {item.fullname}
                                        </TableCell>
                                        <TableCell className="px-0">
                                            {item.email}
                                        </TableCell>
                                        <TableCell
                                            className="px-0"
                                            align="center"
                                        >
                                            {item.gender ? 'Male' : 'Female'}
                                        </TableCell>
                                        <TableCell
                                            className="px-0"
                                            align="center"
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                className="text-white"
                                                onClick={() => {
                                                    setDetailUser(item);
                                                    setOpenDetail(true);
                                                }}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                        <TableCell
                                            className="px-0 border-none"
                                            align="center"
                                        >
                                            <Tooltip title="delete user">
                                                <IconButton
                                                    onClick={() =>
                                                        handleDelete(
                                                            item.users_code
                                                        )
                                                    }
                                                    size="small"
                                                >
                                                    <Icon color="error">
                                                        delete
                                                    </Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>

                    <TablePagination
                        className="px-4"
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={userList?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page'
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page'
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={({ target: { value } }) =>
                            setRowsPerPage(value)
                        }
                    />
                </ScrollBar>
            </Card>
            {openDetail && (
                <DetailUserDialog
                    open={openDetail}
                    handleClose={handleClose}
                    user={detailUser}
                />
            )}
        </div>
    );
};

export default UsersTable;
