import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CircularProgress,
    Grid,
    Icon,
    Typography
 } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import clsx from 'clsx';
import CountUp from 'react-countup';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'fit-content',
        height: 'fit-content',
        gap: '0.5rem'
    },
    activeFilter: {
        color: palette.primary.main,
        backgroundColor: '#FFE1CA',
        borderColor: '#F09856'
    },
    activeProgress: {
        zIndex: 2,
    },
    progressBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0,
        color: '#FFE1CA',
    }
}));

const Visitor = ({ visitor, matchedPeople, percentage, label, visitorValue, matchedPeopleValue, activeFilter, setActiveFilter, setTrendWord }) => {
    const classes = useStyles();
    const [visitorCount, setVisitorCount] = useState(0);
    const [matchedPeopleCount, setMatchedPeopleCount] = useState(0);
    const data = {
        labels: label,
        datasets: [
            {
                label: 'Visitor',
                data: visitorValue,
                fill: false,
                backgroundColor: '#F09856',
                borderColor: '#FAB079',
                tension: 0.3
            },
            {
                label: 'Matched People',
                data: matchedPeopleValue,
                fill: false,
                backgroundColor: '#FFE1CA',
                borderColor: '#FAB079',
                tension: 0.3
            }
        ]
    };

    useEffect(() => {
        setVisitorCount(visitor);
        setMatchedPeopleCount(matchedPeople);
    }, [activeFilter, visitor, matchedPeople]);

    return (
        <Card elevation={6} className="p-6 mb-5">
            <Grid container justifyContent="space-between" spacing={2} className="mb-2">
                <Grid item sm="6" xs="12" className="mb-2">
                    <h4 className={clsx(classes.title, "m-0")}><Icon>insert_chart</Icon> BuddyMed Visitors</h4>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" spacing={2} className="mb-2 p-2">
                <div className="flex mb-2">
                    <div className="flex mr-5 justify-center items-center">
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress variant="determinate" value={percentage?.visitor > 100 ? 100 : percentage?.visitor} color="primary" className={classes.activeProgress} />
                            <CircularProgress
                                variant="determinate"
                                value={100}
                                className={classes.progressBackground}
                            />
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                            <Typography
                                variant="caption"
                                component="div"
                                color="textSecondary"
                                className="flex justify-center items-center"
                            ><Icon color="primary">{percentage?.visitor >= 0 ? 'arrow_upward' : 'arrow_downward'}</Icon></Typography>
                            </Box>
                        </Box>
                        <div className="mx-2 flex flex-column">
                            <small>Visitor</small>
                            <h5 className="font-bold text-primary m-0"><CountUp end={visitorCount || 0} /></h5>
                        </div>
                    </div>
                    <div className="flex justify-center items-center">
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress variant="determinate" value={percentage?.matches > 100 ? 100 : percentage?.matches} color="primary" className={classes.activeProgress} />
                            <CircularProgress
                                variant="determinate"
                                value={100}
                                className={classes.progressBackground}
                            />
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                            <Typography
                                variant="caption"
                                component="div"
                                color="textSecondary"
                                className="flex justify-center items-center"
                            ><Icon color="primary">{percentage?.matches >= 0 ? 'arrow_upward' : 'arrow_downward'}</Icon></Typography>
                            </Box>
                        </Box>
                        <div className="mx-2 flex flex-column">
                            <small>Matched People</small>
                            <h5 className="font-bold text-primary m-0"><CountUp end={matchedPeopleCount || 0} /></h5>
                        </div>
                    </div>
                </div>
                <ButtonGroup variant="outlined">
                    <Button className={clsx(activeFilter === '24 Hours' ? classes.activeFilter : '', "font-bold")} onClick={() => {
                        setActiveFilter('24 Hours');
                        window.localStorage.setItem('active_filter', '24 Hours');
                        setTrendWord('yesterday');

                    }}>Today</Button>
                    <Button className={clsx(activeFilter === '7 Days' ? classes.activeFilter : '', "font-bold")} onClick={() => {
                        setActiveFilter('7 Days');
                        window.localStorage.setItem('active_filter', '7 Days');
                        setTrendWord('last week');
                    }}>This Week</Button>
                    <Button className={clsx(activeFilter === '30 Days' ? classes.activeFilter : '', "font-bold")} onClick={() => {
                        setActiveFilter('30 Days');
                        window.localStorage.setItem('active_filter', '30 Days');
                        setTrendWord('last month');
                    }}>This Month</Button>
                    <Button className={clsx(activeFilter === '12 Months' ? classes.activeFilter : '', "font-bold")} onClick={() => {
                        setActiveFilter('12 Months');
                        window.localStorage.setItem('active_filter', '12 Months');
                    setTrendWord('last year');
                    }}>This Year</Button>
                </ButtonGroup>
            </Grid>
            <div className="container-chart">
                <Bar
                    data={data}
                    options={{
                        plugins: {
                        legend: {
                            display: true,
                            position: "bottom"
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                            ticks: {
                                callback: (val, id, vals) => {
                                    return val % 1 === 0 ? val : null;
                                }
                            },
                            beginAtZero: true
                        }
                    },
                    maintainAspectRatio: false
                    }}
                />
            </div>
        </Card>
    );
};

export default Visitor;
