import React from 'react';

import HobbyTable from './HobbyTable';

const adminRoute = [
    {
        path: '/hobby',
        exact: true,
        component: HobbyTable
    }
];

export default adminRoute;
