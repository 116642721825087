import { Avatar, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    large: {
        width: 100,
        height: 100,
        margin: 'auto'
    }
}));
const DetailUserDialog = ({ user, open, handleClose }) => {
    const classes = useStyles();
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth
            scroll="paper"
        >
            <DialogTitle>
                <h4 className="mb-0">Detail User</h4>
            </DialogTitle>
            <DialogContent dividers>
                <div className="px-6">
                    <div className="w-full text-center mb-2">
                        <Avatar
                            src={user.profile_pict}
                            alt={user.fullname}
                            className={classes.large}
                            sizes="large"
                        />
                    </div>
                    <Table className="w-full">
                        <TableBody>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Full Name
                                </TableCell>
                                <TableCell>{user?.fullname}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Username
                                </TableCell>
                                <TableCell>{user?.username}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    User Code
                                </TableCell>
                                <TableCell>{user.users_code}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Email
                                </TableCell>
                                <TableCell>{user?.email}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Birth Date
                                </TableCell>
                                <TableCell>{user?.birthdate}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Gender
                                </TableCell>
                                <TableCell>
                                    {user?.gender ? 'Male' : 'Female'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    City
                                </TableCell>
                                <TableCell>{user?.city}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Country
                                </TableCell>
                                <TableCell>{user?.country}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    University
                                </TableCell>
                                <TableCell>{user?.university}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Academic Year
                                </TableCell>
                                <TableCell>{user?.academic_year}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Hobbies
                                </TableCell>
                                <TableCell>
                                    {user?.hobbies
                                        ?.map((item) => item.hobbies_name)
                                        ?.join(', ')}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Learner Type
                                </TableCell>
                                <TableCell>{user?.learner_type}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Movie Genre
                                </TableCell>
                                <TableCell>
                                    {user?.movie_genre
                                        ?.map((item) => item.genre_name)
                                        ?.join(', ')}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default DetailUserDialog;
