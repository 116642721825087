import { Button, CircularProgress, Dialog, FormControlLabel, Grid, Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

import { addNewPendingUser, updatePendingUser } from './TableService';

const PendingEditorDialog = ({ pending, open, handleClose }) => {
    const [state, setState] = useState({
        member_code: '',
        group_code: '',
        users_code: '',
        member_status: 2
    });
    const [submit, setSubmit] = useState(false);

    const handleFormSubmit = () => {
        let { member_code } = state;
        setSubmit(true);
        if (member_code) {
            updatePendingUser(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data update successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to update',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        } else {
            addNewPendingUser(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data saved successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to save',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        }
    };

    useEffect(() => {
        if (pending) {
            setState({
                ...pending
            });
        }
    }, [pending]);

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
            <div className="p-6">
                <h4 className="mb-5">
                    {state.member_code ? 'Update Pending User' : 'Add Pending User'}
                </h4>
                <ValidatorForm onSubmit={handleFormSubmit}>
                    <Grid className="mb-4" container spacing={4}>
                        {state?.member_code !== '' ? (
                            <Grid item sm={12} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={state.member_status === 1}
                                            onChange={({ target }) =>
                                                setState((pref) => ({
                                                    ...pref,
                                                    member_status: target.checked
                                                        ? 1
                                                        : 2
                                                }))
                                            }
                                            name="active"
                                            color="primary"
                                        />
                                    }
                                    label="Accept ?"
                                />
                            </Grid>
                        ) : null}
                    </Grid>

                    <div className="flex justify-end items-center">
                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="text-white ml-2"
                            disabled={submit}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-white"
                                />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

export default PendingEditorDialog;
