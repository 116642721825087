import { API, setAuthToken } from 'config/API';

export const getAllUser = async (Filter) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get(`admin/users`);
};
export const deleteUser = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete('admin/users/delete/' + params);
};
