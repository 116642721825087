import Dashboard from './Dashboard';

const dashboardRoutes = [
    {
        path: '/dashboard',
        component: Dashboard
    }
];

export default dashboardRoutes;
