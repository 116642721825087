import { Card, Grid, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    icon: {
        fontSize: '44px',
        opacity: 0.6,
        color: palette.primary.main
    }
}));

const MatchStatCards = ({ match, notMatch, pending }) => {
    const classes = useStyles();
    const [matchCount, setMatchCount] = useState(0);
    const [notMatchCount, setNotMatchCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);

    useEffect(() => {
        if (match) {
            setMatchCount(match);
        }
        if (notMatch) {
            setNotMatchCount(notMatch);
        }
        if (pending) {
            setPendingCount(pending);
        }
    }, [match, notMatch, pending]);

    return (
        <Grid container spacing={3} className="mb-3">
            <Grid item xs={12} md={4}>
                <Card
                    className="flex flex-wrap justify-between items-center p-sm-24 bg-paper"
                    elevation={6}
                >
                    <div className="flex items-center">
                        <Icon className={classes.icon}>shuffle</Icon>
                        <div className="ml-3">
                            <small className="text-muted">Matched People</small>
                            <h6 className="m-0 mt-1 text-primary font-medium">
                                <CountUp end={matchCount} />
                            </h6>
                        </div>
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card
                    className="flex flex-wrap justify-between items-center p-sm-24 bg-paper"
                    elevation={6}
                >
                    <div className="flex items-center">
                        <Icon className={classes.icon}>shuffle</Icon>
                        <div className="ml-3">
                            <small className="text-muted">Not Matched People</small>
                            <h6 className="m-0 mt-1 text-primary font-medium">
                                <CountUp end={notMatchCount} />
                            </h6>
                        </div>
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card
                    className="flex flex-wrap justify-between items-center p-sm-24 bg-paper"
                    elevation={6}
                >
                    <div className="flex items-center">
                        <Icon className={classes.icon}>shuffle</Icon>
                        <div className="ml-3">
                            <small className="text-muted">Pendig People</small>
                            <h6 className="m-0 mt-1 text-primary font-medium">
                                <CountUp end={pendingCount} />
                            </h6>
                        </div>
                    </div>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MatchStatCards;
