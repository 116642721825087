import { API, setAuthToken } from 'config/API';

export const getSetting = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/settings');
};
export const updateSetting = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/setting', params);
};
