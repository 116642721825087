import { API, setAuthToken } from 'config/API';

export const getAllPeopleInQueue = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/people_in_queue');
};
export const deletePeopleInQueue = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete('admin/people_in_queue/delete/' + params);
};
export const addNewPeopleInQueue = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/people_in_queue/add', params);
};
export const updatePeopleInQueue = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/people_in_queue/edit', params);
};
