import React from 'react';

import MatchTable from './MatchTable';

const matchRoute = [
    {
        path: '/match_people',
        exact: true,
        component: MatchTable
    }
];

export default matchRoute;
