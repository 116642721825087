import React from 'react';

import UsersTable from './UsersTable';

const userRoutes = [
    {
        path: '/users',
        exact: true,
        component: UsersTable
    }
];

export default userRoutes;
