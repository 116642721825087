import { Card, Grid, Icon, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useHistory } from 'react-router';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    icon: {
        fontSize: '44px',
        opacity: 0.6,
        color: palette.primary.main
    }
}));

const StatCards = ({ user, male, female }) => {
    const classes = useStyles();
    const history = useHistory();
    const [userCount, setUserCount] = useState(0);
    const [maleCount, setMaleCount] = useState(0);
    const [femaleCount, setFemaleCount] = useState(0);
    const handleUser = () => {
        history.push('/users');
    };

    useEffect(() => {
        if (user) {
            setUserCount(user);
        }
        if (male) {
            setMaleCount(male);
        }
        if (female) {
            setFemaleCount(female);
        }
    }, [user, male, female]);

    return (
        <Grid container spacing={3} className="mb-3">
            <Grid item xs={12} md={4}>
                <Card
                    className="flex flex-wrap justify-between items-center p-sm-24 bg-paper"
                    elevation={6}
                >
                    <div className="flex items-center">
                        <Icon className={classes.icon}>group</Icon>
                        <div className="ml-3">
                            <small className="text-muted">User</small>
                            <h6 className="m-0 mt-1 text-primary font-medium">
                                <CountUp end={userCount} />
                            </h6>
                        </div>
                    </div>
                    <Tooltip title="View Details" placement="top">
                        <IconButton onClick={handleUser}>
                            <Icon>arrow_right_alt</Icon>
                        </IconButton>
                    </Tooltip>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card
                    className="flex flex-wrap justify-between items-center p-sm-24 bg-paper"
                    elevation={6}
                >
                    <div className="flex items-center">
                        <Icon className={classes.icon}>male</Icon>
                        <div className="ml-3">
                            <small className="text-muted">Male User</small>
                            <h6 className="m-0 mt-1 text-primary font-medium">
                            <CountUp end={maleCount} />
                            </h6>
                        </div>
                    </div>
                    <Tooltip title="View Details" placement="top">
                        <IconButton onClick={handleUser}>
                            <Icon>arrow_right_alt</Icon>
                        </IconButton>
                    </Tooltip>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card
                    className="flex flex-wrap justify-between items-center p-sm-24 bg-paper"
                    elevation={6}
                >
                    <div className="flex items-center">
                        <Icon className={classes.icon}>female</Icon>
                        <div className="ml-3">
                            <small className="text-muted">Female User</small>
                            <h6 className="m-0 mt-1 text-primary font-medium">
                            <CountUp end={femaleCount} />
                            </h6>
                        </div>
                    </div>
                    <Tooltip title="View Details" placement="top">
                        <IconButton onClick={handleUser}>
                            <Icon>arrow_right_alt</Icon>
                        </IconButton>
                    </Tooltip>
                </Card>
            </Grid>
        </Grid>
    );
};

export default StatCards;
