import AdminTable from './AdminTable';

const adminRoute = [
    {
        path: '/admins',
        exact: true,
        component: AdminTable
    }
];

export default adminRoute;
