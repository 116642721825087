import React from 'react';

import ZoomTable from './ZoomTable';

const zoomRoute = [
    {
        path: '/zoom_host',
        exact: true,
        component: ZoomTable
    }
];

export default zoomRoute;
