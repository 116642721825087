import Profile from './Profile';

const profileRoutes = [
    {
        path: '/profile',
        component: Profile
    }
];

export default profileRoutes;
