import { API, setAuthToken } from 'config/API';

export const getExam = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/exam_match');
};
export const deleteExam = async (code) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete(`admin/exam_match/delete/${code}`);
};
export const addExam = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/exam_match/add', params);
};
export const updateExam = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/exam_match/edit', params);
};
