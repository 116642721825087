import { Card, Grid, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    statIcon: {
        backgroundColor: palette.primary.main,
        color: palette.secondary.main,
        borderRadius: '0.25rem',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    statTrend: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    statTrendUp: {
        color: palette.success.main
    },
    statTrendDown: {
        color: palette.error.main
    }
}));

const VisitorStat = ({ visitor, matchedPeople, male, female, percentage, trendWord }) => {
    const classes = useStyles();
    const [visitorCount, setVisitorCount] = useState(0);
    const [matchedPeopleCount, setMatchedPeopleCount] = useState(0);
    const [maleCount, setMaleCount] = useState(0);
    const [femaleCount, setFemaleCount] = useState(0);

    useEffect(() => {
        if (visitor) {
            setVisitorCount(visitor);
        }
        if (matchedPeople) {
            setMatchedPeopleCount(matchedPeople);
        }
        if (male) {
            setMaleCount(male);
        }
        if (female) {
            setFemaleCount(female);
        }
    }, [visitor, matchedPeople, male, female]);

    return (
        <Grid container className="mb-5" spacing={2}>
            <Grid item xs={12} md={3}>
                <Card elevation={6} className="p-4 flex justify-start items-center">
                    <div>
                        <Icon className={classes.statIcon}>insert_chart</Icon>
                    </div>
                    <div className="mx-2 flex flex-column">
                        <small>Visitor</small>
                        <p className="font-bold m-0"><CountUp end={visitorCount} /></p>
                        <small className={classes.statTrend}>
                            <span className={clsx(classes.statTrend, percentage?.visitor >= 0 ? classes.statTrendUp : classes.statTrendDown, 'mr-1')}>
                                <Icon fontSize="inherit">{percentage?.visitor >= 0 ? 'arrow_upward' : 'arrow_downward'}</Icon> {Math.abs(percentage?.visitor)}%
                            </span> from {trendWord}
                        </small>
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card elevation={6} className="p-4 flex justify-start items-center">
                    <div>
                        <Icon className={classes.statIcon}>people</Icon>
                    </div>
                    <div className="mx-2 flex flex-column">
                        <small>Matched People</small>
                        <p className="font-bold m-0"><CountUp end={matchedPeopleCount} /></p>
                        <small className={classes.statTrend}>
                            <span className={clsx(classes.statTrend, percentage?.matches >= 0 ? classes.statTrendUp : classes.statTrendDown, 'mr-1')}>
                                <Icon fontSize="inherit">{percentage?.matches >= 0 ? 'arrow_upward' : 'arrow_downward'}</Icon> {Math.abs(percentage?.matches)}%
                            </span> from {trendWord}
                        </small>
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card elevation={6} className="p-4 flex justify-start items-center">
                    <div>
                        <Icon className={classes.statIcon}>male</Icon>
                    </div>
                    <div className="mx-2 flex flex-column">
                        <small>Male</small>
                        <p className="font-bold m-0"><CountUp end={maleCount} /></p>
                        <small className={classes.statTrend}>
                            <span className={clsx(classes.statTrend, percentage?.male >= 0 ? classes.statTrendUp : classes.statTrendDown, 'mr-1')}>
                                <Icon fontSize="inherit">{percentage?.male >= 0 ? 'arrow_upward' : 'arrow_downward'}</Icon> {Math.abs(percentage?.male)}%
                            </span> from {trendWord}
                        </small>
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card elevation={6} className="p-4 flex justify-start items-center">
                    <div>
                        <Icon className={classes.statIcon}>female</Icon>
                    </div>
                    <div className="mx-2 flex flex-column">
                        <small>Female</small>
                        <p className="font-bold m-0"><CountUp end={femaleCount} /></p>
                        <small className={classes.statTrend}>
                            <span className={clsx(classes.statTrend, percentage?.female >= 0 ? classes.statTrendUp : classes.statTrendDown, 'mr-1')}>
                                <Icon fontSize="inherit">{percentage?.female >= 0 ? 'arrow_upward' : 'arrow_downward'}</Icon> {Math.abs(percentage?.female)}%
                            </span> from {trendWord}
                        </small>
                    </div>
                </Card>
            </Grid>
        </Grid>
    )
};

export default VisitorStat;