import { API, setAuthToken } from 'config/API';

export const getAllPendingUser = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/pending_user');
};
export const deletePendingUser = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete('admin/pending_user/delete/' + params);
};
export const addNewPendingUser = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/pending_user/add', params);
};
export const updatePendingUser = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/pending_user/edit', params);
};
