import { API, setAuthToken } from 'config/API';

export const getAllCountry = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/country');
};
export const deleteCountry = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete('admin/country/delete/' + params);
};
export const addNewCountry = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/country/add', params);
};
export const updateCountry = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/country/edit', params);
};
