import {
    Button,
    Card,
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2';

import BTIEditorDialog from './BTIEditorDialog';
import { deleteBTI, getBTI } from './TableService';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    productTable: {
        '& thead': {
            '& th:first-child': {
                paddingLeft: 16
            }
        },
        '& td': {
            borderBottom: 'none'
        },
        '& td:first-child': {
            paddingLeft: '16px !important'
        }
    }
}));

const BTITable = () => {
    const [data, setData] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [dataList, setDataList] = useState(null);
    const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false);
    const [search, setsearch] = useState('');

    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDialogClose = () => {
        setShouldOpenEditorDialog(false);
        updatePageData();
        setData(null);
    };

    const handleDelData = (host_code) => {
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure, you want delete this Data?',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
            icon: 'question',
            cancelButtonColor: '#FF5B62',
            confirmButtonColor: '#BDBDBD'
        }).then((res) => {
            if (res.isConfirmed) {
                deleteBTI(host_code)
                    .then(() => {
                        Swal.fire({
                            title: 'Success',
                            text: 'Data was successfully removed',
                            icon: 'success',
                            timer: 2000
                        }).then(() => {
                            updatePageData();
                        });
                    })
                    .catch((err) => {
                        console.log('error', err);
                        Swal.fire({
                            title: 'Failed',
                            text: 'Data failed to remove',
                            icon: 'error',
                            timer: 2000
                        });
                    });
            }
        });
    };

    const updatePageData = () => {
        getBTI()
            .then(({ data }) => {
                setDataList(data.data);
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const filterDataList = dataList
        ?.filter((item) =>
            Object.keys(item).some((key) =>
                search.includes(key)
                    ? false
                    : item[key]
                          .toString()
                          .toLowerCase()
                          .includes(search.toLowerCase())
            )
        )
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    useEffect(() => {
        updatePageData();
    }, []);

    return (
        <div className="m-sm-30">
            <Button
                className="mb-4 text-white"
                variant="contained"
                color="primary"
                onClick={() => setShouldOpenEditorDialog(true)}
            >
                Add New Data
            </Button>
            <Card className="w-full" elevation={6}>
                <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    className="mb-2 px-4 mt-3"
                >
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            onChange={({ target: { value } }) =>
                                setsearch(value)
                            }
                            size="small"
                            className="w-full"
                            value={search}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon size="small" color="action">
                                            search
                                        </Icon>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>

                <ScrollBar className="flex-grow flex-column relative h-full">
                    <Table
                        className={clsx(
                            'whitespace-pre min-w-750',
                            classes.productTable
                        )}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">No</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterDataList?.length > 0 ? (
                                filterDataList.map((item, index) => (
                                    <TableRow hover key={item.host_code}>
                                        <TableCell
                                            className="px-0"
                                            align="center"
                                        >
                                            {page * rowsPerPage + index + 1}
                                        </TableCell>
                                        <TableCell className="px-0">
                                            {item.bti_text}
                                        </TableCell>
                                        <TableCell className="px-0 border-none">
                                            <IconButton
                                                onClick={() => {
                                                    setData(item);
                                                    setShouldOpenEditorDialog(
                                                        true
                                                    );
                                                }}
                                            >
                                                <Icon color="primary">
                                                    edit
                                                </Icon>
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    handleDelData(item.bti_code)
                                                }
                                            >
                                                <Icon color="error">
                                                    delete
                                                </Icon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : filterDataList?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            ) : dataList === null ? (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        <CircularProgress size={25} />
                                    </TableCell>
                                </TableRow>
                            ) : null}
                        </TableBody>
                    </Table>

                    <TablePagination
                        className="px-4"
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={dataList?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page'
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page'
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={({ target: { value } }) =>
                            setRowsPerPage(value)
                        }
                    />

                    {shouldOpenEditorDialog && (
                        <BTIEditorDialog
                            handleClose={handleDialogClose}
                            open={shouldOpenEditorDialog}
                            data={data}
                        />
                    )}
                </ScrollBar>
            </Card>
        </div>
    );
};

export default BTITable;
