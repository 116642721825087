import { Button, CircularProgress, Dialog, Grid, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

import { getCityByCode } from '../City/TableService';
import { getAllCountry } from '../Country/TableService';
import { addNewUniversity, updateUniversity } from './TableService';

const UniversityEditorDialog = ({ university, open, handleClose }) => {
    const [state, setState] = useState({
        city: '',
        from: '',
        university_name: '',
        university_code: ''
    });
    const [submit, setSubmit] = useState(false);
    const [country, setCountry] = useState([]);
    const [city, setCity] = useState([]);

    const handleFormSubmit = () => {
        let { university_code } = state;
        setSubmit(true);
        if (university_code) {
            updateUniversity(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data update successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to update',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        } else {
            addNewUniversity(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data saved successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to save',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        }
    };

    useEffect(() => {
        getAllCountry()
            .then(({ data }) => {
                setCountry(data.data);
            })
            .catch((err) => {
                console.log('err', err);
            });
        console.log('uni', university);
        if (university) {
            getCity(university?.from);
            setState({
                ...university
            });
        }
    }, [university]);

    const getCity = (code) => {
        getCityByCode(code)
            .then(({ data }) => {
                setCity(data.data);
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
            <div className="p-6">
                <h4 className="mb-5">
                    {state.university_code
                        ? 'Update University'
                        : 'Add University'}
                </h4>
                <ValidatorForm onSubmit={handleFormSubmit}>
                    <Grid className="mb-4" container spacing={4}>
                        <Grid item sm={12} xs={12}>
                            <Select
                                value={state?.from}
                                onChange={({ target: { value } }) => {
                                    setState((pref) => ({
                                        ...pref,
                                        from: value,
                                        city: ''
                                    }));
                                    getCity(value);
                                }}
                                displayEmpty
                                variant="outlined"
                                className="w-full mb-1"
                            >
                                <MenuItem value="">
                                    <em>Select Country</em>
                                </MenuItem>
                                {country?.map((item) => (
                                    <MenuItem
                                        value={item.country_code}
                                        key={item.country_code}
                                        selected={item.country_code === state.from}
                                    >
                                        {item.country_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Select
                                value={state?.city}
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        city: value
                                    }))
                                }
                                displayEmpty
                                variant="outlined"
                                className="w-full mb-1"
                            >
                                <MenuItem value="">
                                    <em>Select City</em>
                                </MenuItem>
                                {city?.map((item) => (
                                    <MenuItem
                                        value={item.city_code}
                                        key={item.city_code}
                                        selected={item.city_code === state.city}
                                    >
                                        {item.city_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className="w-full mb-1"
                                label="Name"
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        university_name: value
                                    }))
                                }
                                type="text"
                                name="name"
                                value={state?.university_name || ''}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    <div className="flex justify-end items-center">
                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="text-white ml-2"
                            disabled={submit}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-white"
                                />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

export default UniversityEditorDialog;
