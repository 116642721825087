import React from 'react';

import BTITable from './BTITable';

const btiRoute = [
    {
        path: '/breake_the_ice',
        exact: true,
        component: BTITable
    }
];

export default btiRoute;
