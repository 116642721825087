import { Card, Grid, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    icon: {
        fontSize: '44px',
        opacity: 0.6,
        color: palette.secondary.main
    }
}));

const PendingStatCards = ({ pending }) => {
    const classes = useStyles();
    const [pendingCount, setPendingCount] = useState(0);

    useEffect(() => {
        if (pending) {
            setPendingCount(pending);
        }
    }, [pending]);

    return (
        <Grid container spacing={3} className="mb-3">
            <Grid item xs={12} md={12}>
                <Card
                    className="flex flex-wrap justify-between items-center p-sm-24 bg-primary"
                    elevation={6}
                >
                    <div className="flex items-center">
                        <Icon className={classes.icon}>hourglass_top</Icon>
                        <div className="ml-3">
                            <small className="text-muted">Pendig User</small>
                            <h6 className="m-0 mt-1 text-secondary font-medium">
                                <CountUp end={pendingCount} />
                            </h6>
                        </div>
                    </div>
                </Card>
            </Grid>
        </Grid>
    );
};

export default PendingStatCards;
