import axios from 'axios';

// Set config defaults when creating the instance
export const URL_API = process.env.REACT_APP_BACKEND_API_URL;
export const API = axios.create({
    baseURL: `${URL_API}api/v1/`
});

export const setAuthToken = (token) => {
    API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    API.defaults.headers.common['Content-Type'] =
        'application/x-www-form-urlencoded';
    API.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
};
