import axios from 'axios';
import { API, setAuthToken } from 'config/API';

export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_ALL_NOTIFICATION = 'DELETE_ALL_NOTIFICATION';

export const getNotification = () => async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.get('admin/notification').then((res) => {
        dispatch({
            type: GET_NOTIFICATION,
            payload: res.data.data
        });
    });
};

export const deleteNotification = (notification_code) => async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.post('admin/notification/delete', { notification_code }).then((res) => {
        dispatch(getNotification());
    });
};

export const deleteAllNotification = () => async (dispatch) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    API.post('admin/notification/delete_all').then((res) => {
        dispatch({
            type: DELETE_ALL_NOTIFICATION,
            payload: []
        });
    });
};

export const createNotification = (notification) => (dispatch) => {
    axios.post('/api/notification/add', { notification }).then((res) => {
        dispatch({
            type: CREATE_NOTIFICATION,
            payload: res.data
        });
    });
};
