import React, { useEffect, useState } from 'react';
import Chart from './shared/Chart';
import StatCards from './shared/StatCards';
import Visitor from './shared/Visitor';
import VisitorStat from './shared/VisitorStat';
import { getDashboard, getDashboardChart, getDashboardVisitor, getDashboardVisitorStat } from './TableService';

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [dashboardVisitorStatData, setDashboardVisitorStatData] = useState(null);
    const [dashboardVisitorData, setDashboardVisitorData] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [activeFilter, setActiveFilter] = useState(() => {
        const currActiveFilter = window.localStorage.getItem('active_filter');

        if (!currActiveFilter) {
            window.localStorage.setItem('active_filter', '24 Hours');
            return '24 Hours';
        }

        return currActiveFilter;
    });
    const [trendWord, setTrendWord] = useState(() => {
        const currActiveFilter = window.localStorage.getItem('active_filter');

        if (!currActiveFilter) {
            window.localStorage.setItem('active_filter', '24 Hours');
            return 'yesterday';
        }

        return {
            '24 Hours': 'yesterday',
            '7 Days': 'last week',
            '30 Days': 'last month',
            '12 Months': 'last year'
        }[currActiveFilter];
    });

    const getChart = ({ month, year }) => {
        setMonth(month);
        setYear(year);
        getDashboardChart({ month, year })
            .then(({ data }) => {
                setChartData(data?.data);
            })
            .catch((err) => console.log('er', err));
    };

    useEffect(() => {
        const date = new Date();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        getChart({ year, month: month < 10 ? '0' + month : month });
        getDashboard()
            .then(({ data }) => {
                setDashboardData(data.data);
            })
            .catch((err) => {
                console.log('error', err);
            });
    }, []);

    useEffect(() => {
        getDashboardVisitorStat({ type: activeFilter })
            .then(({ data }) => {
                setDashboardVisitorStatData(data.data);
            })
            .catch((err) => {
                 console.log('error', err);
             });
        getDashboardVisitor({ type: activeFilter })
             .then(({ data }) => {
                setDashboardVisitorData(data.data);
             })
    }, [activeFilter]);

    return (
        <div className="analytics m-sm-30 mt-7">
            <StatCards
                user={dashboardData?.all_users}
                male={dashboardData?.users_man}
                female={dashboardData?.users_woman}
            />
            <Chart
                label={chartData?.map(data => data.day)}
                value={chartData?.map(data => data.count)}
                changeMonth={(val) => {
                    setMonth(val);
                    getChart({ year, month: val });
                }}
                changeYear={(val) => {
                    setYear(val);
                    getChart({ year: val, month });
                }}
                month={month}
                year={year}
            />
            <VisitorStat
                visitor={dashboardVisitorStatData?.all.visitor}
                matchedPeople={dashboardVisitorStatData?.all.matches}
                male={dashboardVisitorStatData?.all.male}
                female={dashboardVisitorStatData?.all.female}
                percentage={dashboardVisitorStatData?.percentage}
                trendWord={trendWord}
            />
            <Visitor
                visitor={dashboardVisitorStatData?.compare.visitor}
                matchedPeople={dashboardVisitorStatData?.compare.matches}
                percentage={dashboardVisitorStatData?.percentage}
                label={dashboardVisitorData?.map(data => data.label)}
                visitorValue={dashboardVisitorData?.map(data => data.visitor_count)}
                matchedPeopleValue={dashboardVisitorData?.map(data => data.matches_count)}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                setTrendWord={setTrendWord}
            />
        </div>
    );
};

export default Dashboard;
