import { Button, Card, CircularProgress, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import { LOGIN_ERROR, loginWithEmailAndPassword } from '../../redux/actions/LoginActions';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const SignIn = () => {
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [passType, setPassType] = useState('password');

    const dispatch = useDispatch();
    const classes = useStyles();

    const handleChange = ({ target: { name, value } }) => {
        let temp = { ...userInfo };
        temp[name] = value;
        setUserInfo(temp);
    };

    const handleFormSubmit = (event) => {
        setLoading(true);
        dispatch(loginWithEmailAndPassword(userInfo)).then(async (res) => {
            await setLoading(false);
            if (res.type === LOGIN_ERROR) {
                let error = res?.payload?.data;
                Swal.fire({
                    title: 'Error',
                    text:
                        error?.code === 1
                            ? error?.message
                            : 'email or password incorrect',
                    icon: 'error',
                    timer: 2000,
                    confirmButtonText: 'OK'
                });
            } else {
                window.location.href = '/';
            }
        });
    };

    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <div
            className={`bg-login ${clsx(
                'flex justify-center items-center  min-h-full-screen'
            )}`}
        >
            <Grid container justifyContent="center">
                <Grid item lg={4} md={4} sm={6} xs={11}>
                    <Card elevation={2}>
                        <div className="p-8 h-full  relative">
                            <h2 className="text-center mb-5 text-primary">
                                BuddyMed
                            </h2>
                            <ValidatorForm onSubmit={handleFormSubmit}>
                                <TextValidator
                                    className="mb-6 w-full"
                                    variant="outlined"
                                    label="Email"
                                    onChange={handleChange}
                                    type="email"
                                    name="email"
                                    value={userInfo.email || ''}
                                    validators={['required', 'isEmail']}
                                    errorMessages={[
                                        'this field is required',
                                        'email is not valid'
                                    ]}
                                />
                                <TextValidator
                                    className="mb-6 w-full"
                                    label="Password"
                                    variant="outlined"
                                    onChange={handleChange}
                                    name="password"
                                    type={passType}
                                    value={userInfo.password || ''}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="medium"
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setPassType((type) =>
                                                            type === 'password'
                                                                ? 'text'
                                                                : 'password'
                                                        )
                                                    }
                                                >
                                                    {passType === 'password' ? (
                                                        <Visibility fontSize="small" />
                                                    ) : (
                                                        <VisibilityOff fontSize="small" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <div className="flex flex-wrap items-center mb-4 w-100">
                                    <div className="relative">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}
                                            type="submit"
                                            className="text-white"
                                        >
                                            Sign in
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default SignIn;
