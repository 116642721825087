import React from 'react';

import GroupTable from './GroupTable';

const groupRoute = [
    {
        path: '/group',
        exact: true,
        component: GroupTable
    }
];

export default groupRoute;
