import { API, setAuthToken } from 'config/API';

export const getAllAdmin = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/all');
};
export const deleteAdmin = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete('admin/delete/' + params);
};
export const addNewAdmin = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/add', params);
};
export const updateAdmin = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/edit', params);
};
