/* eslint-disable react-hooks/exhaustive-deps */
import { setAuthLoadingStatus } from 'app/redux/actions/LoginActions';
import apiAuthService from 'app/services/apiAuthService';
import React, { Children, Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getNavigationByUser } from '../redux/actions/NavigationAction';
import { setUserData } from '../redux/actions/UserActions';

const checkJwtAuth = async (dispatch, setUserData) => {
    const token = await localStorage.getItem('jwt_token');
    return apiAuthService
        .loginWithToken(token)
        .then((user) => {
            dispatch(setUserData(user));
        })
        .catch(() => {
            dispatch(setUserData(null));
        });
};

const Auth = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setAuthLoadingStatus(true));
        checkJwtAuth(dispatch, setUserData)
            .then(() => {
                dispatch(getNavigationByUser());
            })
            .finally(() => dispatch(setAuthLoadingStatus(false)));
    }, [setUserData, getNavigationByUser]);

    return <Fragment>{children}</Fragment>;
};

export default Auth;
