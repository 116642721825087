import { API, setAuthToken } from 'config/API';

export const getAllHobby = async () => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return await API.get('admin/hobbies');
};
export const deleteHobby = async (params) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return await API.delete(`admin/hobbies/delete/${params}`);
};
export const addNewHobby = async (params) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return await API.post('admin/hobbies/add', params);
};
export const updateHobby = async (params) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return await API.post('admin/hobbies/edit', params);
};
