import {
    Card,
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { parseISO, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import Swal from 'sweetalert2';

import PendingEditorDialog from './PendingEditorDialog';
import PendingStatCards from './PendingStatCards';
import { deletePendingUser, getAllPendingUser } from './TableService';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    productTable: {
        '& thead': {
            '& th:first-child': {
                paddingLeft: 16
            }
        },
        '& td': {
            borderBottom: 'none'
        },
        '& td:first-child': {
            paddingLeft: '16px !important'
        }
    },
    badge: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        fontWeight: 'bold',
        width: '110px',
        textAlign: 'center',
        display: 'inline-block'
    },
    pendingBadge: {
        backgroundColor: '#FEF5EE',
        color: '#F09856',
    }
}));

const PendingTable = () => {
    const [pending, setPending] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [pendingList, setPendingList] = useState(null);
    const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false);
    const [search, setsearch] = useState('');

    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDialogClose = () => {
        setShouldOpenEditorDialog(false);
        updatePageData();
        setPending(null);
    };

    const handleDelPending = (member_code) => {
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure, you want delete this Pending User?',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
            icon: 'question',
            cancelButtonColor: '#FF5B62',
            confirmButtonColor: '#BDBDBD'
        }).then((res) => {
            if (res.isConfirmed) {
                deletePendingUser(member_code)
                    .then(() => {
                        Swal.fire({
                            title: 'Success',
                            text: 'Pending User was successfully removed',
                            icon: 'success',
                            timer: 2000
                        }).then(() => {
                            updatePageData();
                        });
                    })
                    .catch((err) => {
                        console.log('error', err);
                        Swal.fire({
                            title: 'Failed',
                            text: 'Pending User failed to remove',
                            icon: 'error',
                            timer: 2000
                        });
                    });
            }
        });
    };

    const updatePageData = () => {
        getAllPendingUser()
            .then(({ data }) => {
                setPendingList(data.data);
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    useEffect(() => {
        updatePageData();
    }, []);

    const dataPending = pendingList
        ?.filter((item) =>
            Object.keys(item).some((key) =>
                search.includes(key)
                    ? false
                    : item[key]
                          .toString()
                          .toLowerCase()
                          .includes(search.toLowerCase())
            )
        )
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const pendingCount = pendingList?.length;

    return (
        <div className="m-sm-30">
            <PendingStatCards pending={pendingCount} />
            {/* <Button
                className="mb-4 text-white"
                variant="contained"
                color="primary"
                onClick={() => setShouldOpenEditorDialog(true)}
            >
                Add New Pending User
            </Button> */}
            <Card className="w-full" elevation={6}>
                <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    className="mb-2 px-4 mt-3"
                >
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            onChange={({ target: { value } }) =>
                                setsearch(value)
                            }
                            size="small"
                            className="w-full"
                            value={search}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon size="small" color="action">
                                            search
                                        </Icon>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <ScrollBar className="flex-grow flex-column relative h-full">
                    <Table
                        className={clsx(
                            'whitespace-pre min-w-750',
                            classes.productTable
                        )}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">No</TableCell>
                                <TableCell>Group</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>University</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataPending?.length > 0 ? (
                                dataPending.map((item, index) => (
                                    <TableRow hover key={item.member_code}>
                                        <TableCell
                                            className="px-0"
                                            align="center"
                                        >
                                            {page * rowsPerPage + index + 1}
                                        </TableCell>
                                        <TableCell className="px-0">
                                            {item.group || '-'}
                                        </TableCell>
                                        <TableCell className="px-0">
                                            {item.fullname || '-'}
                                        </TableCell>
                                        <TableCell className="px-0">
                                            {item.university_name || '-'}
                                        </TableCell>
                                        <TableCell className="px-0">
                                            {format(parseISO(item.time), 'E, dd MMM yyyy hh:mm a') || '-'}
                                        </TableCell>
                                        <TableCell className="px-0">
                                            <div className={clsx(classes.badge, classes.pendingBadge)}>
                                                Pending
                                            </div>
                                        </TableCell>
                                        <TableCell className="px-0 border-none" align='center'>
                                            <IconButton
                                                onClick={() => {
                                                    setPending(item);
                                                    setShouldOpenEditorDialog(
                                                        true
                                                    );
                                                }}
                                            >
                                                <Icon color="primary">
                                                    edit
                                                </Icon>
                                            </IconButton>
                                            {/* <IconButton
                                                onClick={() =>
                                                    handleDelGroup(
                                                        item.group_code
                                                    )
                                                }
                                            >
                                                <Icon color="error">
                                                    delete
                                                </Icon>
                                            </IconButton> */}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : dataPending?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            ) : pendingList === null ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        <CircularProgress size={25} />
                                    </TableCell>
                                </TableRow>
                            ) : null}
                        </TableBody>
                    </Table>

                    <TablePagination
                        className="px-4"
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={pendingList?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page'
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page'
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={({ target: { value } }) =>
                            setRowsPerPage(value)
                        }
                    />

                    {shouldOpenEditorDialog && (
                        <PendingEditorDialog
                            handleClose={handleDialogClose}
                            open={shouldOpenEditorDialog}
                            pending={pending}
                        />
                    )}
                </ScrollBar>
            </Card>
        </div>
    );
};

export default PendingTable;
