import { Button, CircularProgress, Dialog, Grid, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

import { addNewMatchPeople, updateMatchPeople } from './TableService';

const MatchEditorDialog = ({ match, open, handleClose }) => {
    const [state, setState] = useState({
        matched_code: '',
        from_user: '',
        from_fullname: '',
        to_user: '',
        to_fullname: '',
        status: ''
    });
    const [submit, setSubmit] = useState(false);

    const handleFormSubmit = () => {
        let { matched_code } = state;
        setSubmit(true);
        if (matched_code) {
            updateMatchPeople(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data update successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to update',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        } else {
            addNewMatchPeople(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data saved successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to save',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        }
    };

    useEffect(() => {
        if (match) {
            setState({
                ...match
            });
        }
    }, [match]);

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
            <div className="p-6">
                <h4 className="mb-5">
                    {state.matched_code ? 'Update Matched People' : 'Add Matched People'}
                </h4>
                <ValidatorForm onSubmit={handleFormSubmit}>
                    <Grid className="mb-4" container spacing={4}>
                        <Grid item sm={12} xs={12}>
                            <Select
                                value={state?.status}
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        status: value
                                    }))
                                }
                                displayEmpty
                                variant="outlined"
                                className="w-full mb-1"
                            >
                                <MenuItem value="">
                                    <em>Select Status</em>
                                </MenuItem>
                                {[
                                    {
                                        value: 0,
                                        name: 'Not Matched'
                                    },
                                    {
                                        value: 1,
                                        name: 'Matched'
                                    },
                                    {
                                        value: 2,
                                        name: 'Pending'
                                    }
                                ].map((item) => (
                                    <MenuItem
                                        value={item.value}
                                        key={item.value}
                                        selected={item.value === state?.status}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>

                    <div className="flex justify-end items-center">
                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="text-white ml-2"
                            disabled={submit}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-white"
                                />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

export default MatchEditorDialog;
