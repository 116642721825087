import React from 'react';
import { Redirect } from 'react-router-dom';

import adminRoute from './views/Admin/AdminRoutes';
import BTIRoutes from './views/BTI/BTIRoutes';
import cityRoutes from './views/City/CityRoutes';
import conferenceRoutes from './views/Conference/ConferenceRoutes';
import countryRoute from './views/Country/CountryRoutes';
import dashboardRoutes from './views/dashboard/DashboardRoutes';
import examRoute from './views/Exam/ExamRoutes';
import groupRoute from './views/Group/GroupRoutes';
import hobbyRoute from './views/Hobby/HobbyRoutes';
import learnerRoute from './views/Learner/LearnerRoutes';
import matchRoute from './views/Match/MatchRoutes';
import movieRoute from './views/Movie/MovieRoutes';
import pendingRoute from './views/Pending/PendingRoutes';
import profileRoutes from './views/Profile/ProfileRoutes';
import queueRoute from './views/Queue/QueueRoutes';
import settingRoute from './views/Setting/SettingRoutes';
import universityRoute from './views/University/UniversityRoutes';
import userRoutes from './views/user/UserRoutes';
import zoomRoute from './views/Zoom/ZoomRoutes';

const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/dashboard" />
    }
];

const errorRoute = [
    {
        component: () => <Redirect to="/session/404" />
    }
];

const routes = [
    ...dashboardRoutes,
    ...userRoutes,
    ...adminRoute,
    ...groupRoute,
    ...conferenceRoutes,
    ...pendingRoute,
    ...matchRoute,
    ...queueRoute,
    ...hobbyRoute,
    ...countryRoute,
    ...cityRoutes,
    ...zoomRoute,
    ...examRoute,
    ...movieRoute,
    ...learnerRoute,
    ...universityRoute,
    ...settingRoute,
    ...BTIRoutes,
    ...redirectRoute,
    ...profileRoutes,
    ...errorRoute
];

export default routes;
