import { API, setAuthToken } from 'config/API';

export const getProfile = async () => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return await API.get('admin/profile');
};

export const updateProfile = async (params) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return await API.post('admin/edit', params);
};

export const resetPass = async (params) => {
    const token = localStorage.getItem('jwt_token');
    setAuthToken(token);
    return await API.post('admin/reset_password', params);
};
