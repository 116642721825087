import { Button, Checkbox, CircularProgress, Dialog, FormControlLabel, Grid } from '@material-ui/core';
import { InputImage } from 'app/components';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

import { addNewAdmin, updateAdmin } from './TableService';

const AdminEditorDialog = ({ admin, open, handleClose }) => {
    const [state, setState] = useState({
        admin_code: '',
        email: '',
        fullname: '',
        password: '',
        profile_pict: '',
        profile_pict_ori: '',
        profile_prev: ''
    });
    const [passType, setPassType] = useState('password');
    const [submit, setSubmit] = useState(false);

    const handleFormSubmit = () => {
        let { admin_code } = state;
        setSubmit(true);
        if (admin_code) {
            updateAdmin(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data update successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to update',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        } else {
            addNewAdmin(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data saved successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to save',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        }
    };

    useEffect(() => {
        if (admin) {
            setState({
                ...admin,
                profile_prev: admin?.profile_pict,
                profile_pict: '',
                profile_pict_ori: admin?.profile_pict_origin
            });
        }
    }, [admin]);

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
            <div className="p-6">
                <h4 className="mb-5">
                    {state.admin_code ? 'Update Admin' : 'Add Admin'}
                </h4>
                <ValidatorForm onSubmit={handleFormSubmit}>
                    <Grid className="mb-4" container spacing={4}>
                        <Grid item sm={12} xs={12}>
                            <InputImage
                                placeholder="Image Profile"
                                uploadFoto={(file, path) => {
                                    setState((pref) => ({
                                        ...pref,
                                        profile_pict: file
                                    }));
                                }}
                                img_preview={state?.profile_prev}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className="w-full mb-1"
                                label="Name"
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        fullname: value
                                    }))
                                }
                                type="text"
                                name="name"
                                value={state?.fullname || ''}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className="w-full mb-1"
                                label="Email"
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        email: value
                                    }))
                                }
                                type="text"
                                name="email"
                                value={state?.email || ''}
                                validators={['required', 'isEmail']}
                                errorMessages={[
                                    'this field is required',
                                    'email is not valid'
                                ]}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className="w-full mb-1"
                                label="Password"
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        password: value
                                    }))
                                }
                                type={passType}
                                name="password"
                                value={state?.password || ''}
                                validators={
                                    state.admin_code
                                        ? []
                                        : [
                                              'required',
                                              'minStringLength: 8',
                                              'maxStringLength: 15'
                                          ]
                                }
                                errorMessages={[
                                    'this field is required',
                                    'Min string for password is 8 character',
                                    'Max string for password is 15 character'
                                ]}
                                variant="outlined"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={passType === 'text'}
                                        onChange={(e) =>
                                            setPassType(
                                                e.target.checked
                                                    ? 'text'
                                                    : 'password'
                                            )
                                        }
                                        name="password"
                                        color="primary"
                                    />
                                }
                                label="Show Password"
                            />
                        </Grid>
                    </Grid>

                    <div className="flex justify-end items-center">
                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="text-white ml-2"
                            disabled={submit}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-white"
                                />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

export default AdminEditorDialog;
