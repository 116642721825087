import { API, setAuthToken } from 'config/API';

export const getHost = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/host');
};
export const deleteHost = async (code) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.delete(`admin/host/delete/${code}`);
};
export const addHost = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/host/add', params);
};
export const updateHost = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/host/edit', params);
};
