import { Button, Card, CircularProgress, Grid, Tab, Tabs } from '@material-ui/core';
import { InputImage } from 'app/components';
import { setUserData } from 'app/redux/actions/UserActions';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import { getProfile, resetPass, updateProfile } from '../Profile/ProfileService';

const Profile = () => {
    const [tabActive, settabActive] = useState('profile');
    const authUser = window.localStorage.getItem('auth_user');
    const authUserJSON = JSON.parse(authUser);
    const dispatch = useDispatch();
    const [user, setUser] = useState({
        fullname: authUserJSON.fullname,
        admin_code: authUserJSON.admin_code,
        email: authUserJSON.email,
        password: '',
        profile_pict: '',
        profile_pict_ori: '',
        profile_prev: authUserJSON.profile_pict
    });

    const [submit, setSubmit] = useState(false);
    const [submitReset, setSubmitReset] = useState(false);

    const handleChange = (event, newValue) => {
        settabActive(newValue);
    };

    const [password, setPassword] = useState({
        password_old: '',
        password: '',
        password_confirmation: ''
    });

    const handleSubmitProfile = () => {
        setSubmit(true);
        updateProfile(user)
            .then(() => {
                Swal.fire({
                    title: 'Success',
                    text: 'update was successfully',
                    icon: 'success',
                    timer: 2000
                });
                setSubmit(false);

                getProfile().then((res) => {
                    let result = res?.data?.data;
                    dispatch(setUserData(result));
                    setUser({
                        ...user,
                        fullname: result?.fullname,
                        profile_prev: result?.profile_pict,
                        profile_pict_ori: result?.profile_pict_origin,
                        email: result?.email,
                        admin_code: result?.admin_code
                    });
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Failed',
                    text: 'update failed',
                    icon: 'error',
                    timer: 2000
                });
                setSubmit(false);
            });
    };

    const handleSubmitPass = () => {
        setSubmitReset(true);
        resetPass(password)
            .then(() => {
                Swal.fire({
                    title: 'Success',
                    text: 'reset password was successfully',
                    icon: 'success',
                    timer: 2000
                });
                setSubmitReset(false);
                setPassword({
                    password: '',
                    conf_password: ''
                });
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    title: 'Failed',
                    text: 'reset password failed',
                    icon: 'error',
                    timer: 2000
                });
                setSubmitReset(false);
            });
    };

    useEffect(() => {
        getProfile()
            .then(({ data }) => {
                console.log('data', data);
                setUser((pref) => ({
                    ...pref,
                    fullname: data?.data?.fullname,
                    profile_prev: data?.data?.profile_pict,
                    profile_pict_ori: data?.data?.profile_pict_origin,
                    email: data?.data?.email,
                    admin_code: data?.data?.admin_code
                }));
            })
            .catch((err) => {
                console.log('error', err);
            });

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== password.password) {
                return false;
            }
            return true;
        });
    }, [password]);

    return (
        <div className="m-sm-30">
            <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Card
                        className="px-4 py-8 flex items-center justify-center flex-column"
                        elevation="3"
                    >
                        <img
                            className="_img-profile bg-light-gray mb-5"
                            src={user?.profile_prev}
                            alt="profile"
                        />
                        <h6 className="text-center mb-0">{user?.fullname}</h6>
                        <h6 className="text-center">{user?.email}</h6>
                    </Card>
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                    <Card className="px-4 py-2" elevation="3">
                        <Tabs
                            value={tabActive}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChange}
                        >
                            <Tab label="Edit Profile" value="profile" />
                            <Tab label="Edit Password" value="password" />
                        </Tabs>
                        {tabActive === 'profile' ? (
                            <ValidatorForm
                                onSubmit={handleSubmitProfile}
                                className="pt-6 pb-4"
                            >
                                <Grid className="mb-2" container spacing={4}>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            label="Email"
                                            disabled
                                            type="email"
                                            name="email"
                                            value={user?.email || ''}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full"
                                            label="Name"
                                            onChange={({ target: { value } }) =>
                                                setUser((pref) => ({
                                                    ...pref,
                                                    fullname: value
                                                }))
                                            }
                                            type="text"
                                            name="fullname"
                                            value={user?.fullname || ''}
                                            validators={['required']}
                                            errorMessages={[
                                                'this field is required'
                                            ]}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12} className="mb-1">
                                        <InputImage
                                            placeholder="Profile"
                                            uploadFoto={(file, path) =>
                                                setUser((pref) => ({
                                                    ...pref,
                                                    profile_pict: file,
                                                    profile_prev: path
                                                }))
                                            }
                                            img_preview={user?.profile_prev}
                                        />
                                    </Grid>
                                </Grid>

                                <div className="flex justify-between items-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className="text-white"
                                        disabled={submit}
                                    >
                                        {submit ? (
                                            <CircularProgress
                                                size={24}
                                                className="text-white"
                                            />
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </div>
                            </ValidatorForm>
                        ) : (
                            <ValidatorForm
                                onSubmit={handleSubmitPass}
                                className="pt-6 pb-4"
                            >
                                <Grid className="mb-4" container spacing={4}>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full mb-1"
                                            label="Old Password"
                                            onChange={({ target: { value } }) =>
                                                setPassword((pref) => ({
                                                    ...pref,
                                                    password_old: value
                                                }))
                                            }
                                            type="password"
                                            name="password_old"
                                            value={password?.password_old || ''}
                                            // validators={[
                                            //     'required',
                                            //     'minStringLength: 4',
                                            //     'maxStringLength: 15'
                                            // ]}
                                            // errorMessages={[
                                            //     'this field is required',
                                            //     'Min string for password is 4 character',
                                            //     'Max string for password is 15 character'
                                            // ]}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full mb-1"
                                            label="New Password"
                                            onChange={({ target: { value } }) =>
                                                setPassword((pref) => ({
                                                    ...pref,
                                                    password: value
                                                }))
                                            }
                                            type="password"
                                            name="password"
                                            value={password?.password || ''}
                                            validators={[
                                                'required',
                                                'minStringLength: 4',
                                                'maxStringLength: 15'
                                            ]}
                                            errorMessages={[
                                                'this field is required',
                                                'Min string for password is 4 character',
                                                'Max string for password is 15 character'
                                            ]}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextValidator
                                            className="w-full mb-1"
                                            label="Confirm Password"
                                            onChange={({ target: { value } }) =>
                                                setPassword((pref) => ({
                                                    ...pref,
                                                    password_confirmation: value
                                                }))
                                            }
                                            type="password"
                                            name="password_confirmation"
                                            value={
                                                password?.password_confirmation ||
                                                ''
                                            }
                                            validators={[
                                                'required',
                                                'isPasswordMatch'
                                            ]}
                                            errorMessages={[
                                                'this field is required',
                                                'Password not match'
                                            ]}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>

                                <div className="flex justify-between items-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className="text-white"
                                        disabled={submitReset}
                                    >
                                        {submitReset ? (
                                            <CircularProgress
                                                size={24}
                                                className="text-white"
                                            />
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </div>
                            </ValidatorForm>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default Profile;
