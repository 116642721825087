import React from 'react';

import ConferenceTable from './ConferenceTable';

const conferenceRoute = [
    {
        path: '/conference',
        exact: true,
        component: ConferenceTable
    }
];

export default conferenceRoute;
