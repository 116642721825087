import React from 'react';

import SettingTable from './SettingTable';

const settingRoute = [
    {
        path: '/setting',
        exact: true,
        component: SettingTable
    }
];

export default settingRoute;
