import React from 'react';

import MovieTable from './MovieTable';

const movieRoute = [
    {
        path: '/movie_genre',
        exact: true,
        component: MovieTable
    }
];

export default movieRoute;
