import {
    Button,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

import { addExam, updateExam } from './TableService';

const ExamEditorDialog = ({ exam, open, handleClose }) => {
    const [state, setState] = useState({
        code: '',
        name: '',
        type: ''
    });
    const [submit, setSubmit] = useState(false);

    const handleFormSubmit = () => {
        let { code } = state;
        setSubmit(true);
        if (code) {
            updateExam(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data update successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to update',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        } else {
            addExam(state)
                .then(() => {
                    Swal.fire({
                        title: 'Success',
                        text: 'data saved successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'success'
                    }).then(() => {
                        setSubmit(false);
                        handleClose();
                    });
                })
                .catch((err) => {
                    console.log('err', err);
                    const error = err.response.data;
                    Swal.fire({
                        title: 'Failed',
                        text:
                            error?.code === 1
                                ? error?.data[0]
                                : 'data failed to save',
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error'
                    }).then(() => {
                        setSubmit(false);
                    });
                });
        }
    };

    useEffect(() => {
        if (exam) {
            setState({
                ...exam,
                profile_prev: exam?.profile_pict,
                profile_pict: ''
            });
        }
    }, [exam]);

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
            <div className="p-6">
                <h4 className="mb-5">
                    {state.code ? 'Update Exam Type' : 'Add Exam Type'}
                </h4>
                <ValidatorForm onSubmit={handleFormSubmit}>
                    <Grid className="mb-4" container spacing={4}>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className="w-full mb-1"
                                label="Title"
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        name: value
                                    }))
                                }
                                type="text"
                                name="name"
                                value={state?.name || ''}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <SelectValidator
                                variant="outlined"
                                className="mb-4 w-full"
                                label="Select Category"
                                onChange={({ target: { value } }) =>
                                    setState((pref) => ({
                                        ...pref,
                                        type: value
                                    }))
                                }
                                name="type"
                                value={state?.type || ''}
                            >
                                <MenuItem value="a" disabled>
                                    <em>Select Category</em>
                                </MenuItem>
                                <MenuItem value="1">Medical License</MenuItem>
                                <MenuItem value="2">Clinical</MenuItem>
                                <MenuItem value="3">Basic Medical</MenuItem>
                                <MenuItem value="4">Basic Science</MenuItem>
                            </SelectValidator>
                        </Grid>
                    </Grid>

                    <div className="flex justify-end items-center">
                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="text-white ml-2"
                            disabled={submit}
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-white"
                                />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

export default ExamEditorDialog;
