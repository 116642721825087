import { Avatar, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    large: {
        width: 100,
        height: 100,
        margin: 'auto'
    },
    badge: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        fontWeight: 'bold',
        width: '110px',
        textAlign: 'center',
        display: 'inline-block'
    },
    activeBadge: {
        backgroundColor: '#E9F7EB',
        color: '#05B020',
    },
    nonActiveBadge: {
        backgroundColor: '#FAE7E7',
        color: '#D10D0D',
    }
}));
const DetailGroupDialog = ({ group, open, handleClose }) => {
    console.log({group});
    const classes = useStyles();
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="sm"
            fullWidth
            scroll="paper"
        >
            <DialogTitle>
                <h4 className="mb-0">Detail Group</h4>
            </DialogTitle>
            <DialogContent dividers>
                <div className="px-6">
                    <div className="w-full text-center mb-2">
                        <Avatar
                            src={group?.group_pict}
                            alt={group?.group_name}
                            className={classes.large}
                            sizes="large"
                        />
                    </div>
                    <Table className="w-full">
                        <TableBody>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Name
                                </TableCell>
                                <TableCell>{group?.group_name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Group Code
                                </TableCell>
                                <TableCell>{group?.group_code}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Created By
                                </TableCell>
                                <TableCell>{group?.created_by}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Description
                                </TableCell>
                                <TableCell>{group?.group_desc}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Status
                                </TableCell>
                                <TableCell>
                                <div className={clsx(classes.badge, group?.is_open ? classes.activeBadge : classes.nonActiveBadge)}>
                                    {group?.is_open ? 'Active' : 'Non - Active'}
                                </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Member
                                </TableCell>
                                <TableCell>
                                    <List>
                                        {group?.members.map(member => (
                                            <ListItem disablePadding>
                                                <ListItemIcon>
                                                    <Avatar
                                                        src={member.profile_pict}
                                                        alt={member.fullname}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={
                                                    <>
                                                        <p>{member.fullname}</p>
                                                        <small className={`rounded ${member.role ? 'bg-primary' : 'bg-secondary'} elevation-z0 px-2 py-2px`}>
                                                            {member.role ? 'Admin' : 'Member'}
                                                        </small>
                                                        {member.member_status === 2 && <small className={`rounded bg-primary elevation-z0 px-2 py-2px ml-1`}>
                                                            Pending
                                                        </small>}
                                                    </>
                                                } />
                                            </ListItem>
                                        ))}
                                    </List>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default DetailGroupDialog;
