import { API, setAuthToken } from 'config/API';

export const getAllConferences = async () => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.get('admin/conferences');
};
export const deleteConferences = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/conferences/delete/' + params);
};
export const addNewConferences = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/conferences/add', params);
};
export const updateConferences = async (params) => {
    const token = await localStorage.getItem('jwt_token');
    setAuthToken(token);
    return API.post('admin/conferences/edit', params);
};
