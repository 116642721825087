import React from 'react';

import UniversityTable from './UniversityTable';

const universityRoute = [
    {
        path: '/university',
        exact: true,
        component: UniversityTable
    }
];

export default universityRoute;
